import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ReviewCard from "../components/review-card";
import Layout from "../components/layout";
import ServiceHero from "../components/service-hero";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://www.local-marketing-reports.com/external/showcase-reviews/widgets/7a105739c741fcc4cec345a1270bd3f9acdc2878`
      )
      .then((res) => {
        const reviewData = res.data.results;
        setReviews(reviewData);
        setReviews(reviewData);
        document.getElementById("loading-spinner").style.display = "none";
        document.getElementById("no-reviews").style.display = "block";
      });
  });

  const data = useStaticQuery(
    graphql`
      query {
        pageData: wpPage(slug: { eq: "reviews" }) {
          reviewsFields {
            reviewsBanner {
              reviewsBannerBackgroundImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              reviewsBannerHeading
            }
          }
          seoFields {
            title
            opengraphTitle
            opengraphDescription
            metaDescription
            localBusinessSchema
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    original {
                      height
                      width
                    }
                  }
                  publicURL
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const {
    pageData: { seoFields, reviewsFields },
  } = data;

  const { reviewsBanner } = reviewsFields;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Example",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Reviews",
        item: {
          url: `${siteUrl}/reviews`,
          id: `${siteUrl}/reviews`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      {reviewsBanner.reviewsBannerHeading && (
        <section>
          <ServiceHero
            title={reviewsBanner.reviewsBannerHeading}
            backgroundImage={
              reviewsBanner.reviewsBannerBackgroundImage.node?.localFile
                .childImageSharp.gatsbyImageData
            }
          />
        </section>
      )}
      <Container className="py-5">
        <Col>
          <Row className=" " style={{ minHeight: "50vh" }}>
            <Spinner
              className="mx-auto"
              id="loading-spinner"
              variant="primary"
              animation="border"
            >
              <span className="sr-only"></span>
            </Spinner>

            {reviews.map((review) => (
              <Col className="pb-5" lg={4}>
                {" "}
                <ReviewCard review={review} />
              </Col>
            ))}
          </Row>
        </Col>
      </Container>
    </Layout>
  );
};

export default Reviews;
